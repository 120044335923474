import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import moment from 'moment';
import { clearSession } from './helpers';

export const API_ROOT = 'https://api.islandhop.in';
export const APP_DOMAIN = 'islandhop.in';
export const BASE_URL = 'https://api.islandhop.in';
//export const BASE_URL_LIVE = 'https://app.travelatlas.com';
export const BASE_URL_LIVE = 'https://api.islandhop.in';
//export const BASE_URL_LIVE = 'http://localhost:3000';
export const APP_VERSION = '3.5';

export function verifyAndUpdateAppVersion() {
  // Display App Version
  console.log('APP VERSION', APP_VERSION);

  const version = localStorage.getItem("APP_VERSION");
  if (version === null || version != APP_VERSION) {
    localStorage.setItem("APP_VERSION", APP_VERSION);
    clearSession();
  }
}

export function validateEmail(email) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return (true)
  }
  return (false)
}

export function stripHtmlTags(str, length) {
  if ((str === null) || (str === '')) {
    return false;
  } else {
    str = str.toString();
    str = str.replace(/<[^>]*>/g, '')
    if (length && length > 0 && str.length > length) {
      str = str.substr(0, length);
      str = str + "...";
    }
  }
  return str;
}

export const KB_DEFAULT_LOGO_IMAGE = "/images/company-logo.png";
export const KB_DEFAULT_FAVICON_IMAGE = "/images/favicon-icon.png";


export function FromatValidationErrors(errors, error_keys) {
  Object.keys(errors).map(function (key) {
    error_keys[key] = errors[key][0];
    return key;
  });
  return error_keys;
}

export function jsUcfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function _dispatch(nextState, rerender = false, compName = null) {
  rerender = rerender
    ? new Date().getTime()
    : nextState.status;
  return {
    ...nextState,
    status: rerender,
    compName
  }
}

export function currencyFormat(amount, currency, showCurrencySymbol = true) {
  let currencyLocale = '';
  switch (currency) {
    case 'USD':
      currencyLocale = 'en-US';
      currency = '$';
      break;
    case 'INR':
      currencyLocale = 'en-IN';
      currency = '₹';
      break;
    default:
      currencyLocale = 'en-IN';
      currency = '₹';
      break;
  }

  amount = parseInt(amount).toLocaleString(currencyLocale);

  return showCurrencySymbol ? `${currency}${amount}` : amount;
}

export function dateFormat($dateTime) {
  return moment.utc($dateTime).fromNow();
}

export function arrayColumn(array, columnName, sort = false) {
  array = array.map((value, index) => {
    return value[columnName];
  });
  array = array.filter((item, pos) => {
    return array.indexOf(item) === pos;
  })
  if (sort === true)
    array = array.sort();

  return array;
}

export const activities = [
  'Skuba Diving', 'Paragliding', 'Sight Seeing', 'Cruise', 'Water park', 'Trekking', 'Houseboat', 'Paragliding', 'Sight Seeing', 'Cruise', 'Water park', 'Houseboat'
]

export const months = ['', 'Janaury', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export const shortMonths = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const regular_months = [
  { id: 1, name: 'JAN', fullName: 'JANUARY' },
  { id: 2, name: 'FEB', fullName: 'FEBRUARY' },
  { id: 3, name: 'MAR', fullName: 'MARCH' },
  { id: 4, name: 'APR', fullName: 'APRIL' },
  { id: 5, name: 'MAY', fullName: 'MAY' },
  { id: 6, name: 'JUN', fullName: 'JUNE' },
  { id: 7, name: 'JUL', fullName: 'JULY' },
  { id: 8, name: 'AUG', fullName: 'AUGUST' },
  { id: 9, name: 'SEP', fullName: 'SEPTEMBER' },
  { id: 10, name: 'OCT', fullName: 'OCTOBER' },
  { id: 11, name: 'NOV', fullName: 'NOVEMBER' },
  { id: 12, name: 'DEC', fullName: 'DECEMBER' }
]
export const shortDayNames = ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'];
export const shortDayObj = { 1: 'Mon', 2: 'Tue', 3: 'Wed', 4: 'Thur', 5: 'Fri', 6: 'Sat', 7: 'Sun' };
export const budgetPreferences = ['', 'Standard'];
export const tripDuration = ['', '1-3', '4-6', '7-9', '10-12', 'More than 12'];
export const quote_status = ['Active', 'Active', 'Rejected', 'Invoice Updated', 'Cancelled', 'Converted', 'Booking Cancelled'];
export const quote_status_clas = ['active', 'active', 'rejected', 'invoice-update', 'cancel', 'converted', 'b-cancel'];

export const GLOBAL_ERROR = 'GLOBAL_ERROR';
export const LOCAL_ERROR = 'LOCAL_ERROR';
export const FACEBOOK_APP_ID = '308915869913933';

export const  countUniqueElements = (string) => {
  if(!string){
    return "";
  }
  let arr = string.split(',');
  console.log('aaaa', arr)
  // Create an object to store element counts
  const elementCounts = {};

  // Count occurrences of each element in the array
  arr.forEach(element => {
    element = element.trim();
    if (elementCounts[element]) {
      elementCounts[element]++;
    } else {
      elementCounts[element] = 1;
    }
  });

  // Convert object to array of unique elements and their counts
  const uniqueElements = Object.keys(elementCounts).map(element => ({
    element,
    count: elementCounts[element]
  }));
  console.log(uniqueElements, 'aaaaaa')
  let html = "";
  if(uniqueElements.length){
    uniqueElements.forEach((obj) => {
      html += obj.element + "("+obj.count+") ";
    })
  }
  return html;
  //return uniqueElements;
}

export function nl2br (str, replaceMode, isXhtml) {

  var breakTag = (isXhtml) ? '<br />' : '<br>';
  var replaceStr = (replaceMode) ? '$1'+ breakTag : '$1'+ breakTag +'$2';
  return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
}

