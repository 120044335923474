import React, { Component } from 'react';
import HomeSearch from './HomeSearch';
import { Link } from 'react-router-dom';
import { API_ROOT } from '../../constants';
import axios from 'axios';
import '../../jquery.bxslider';

export default class HomeBanner extends Component {

  constructor(props) {
    super(props);
    this.state = {
      banners: null
    }
    this.slider = null;
    this.config = {
      controls: false,
      pause: 8000,
      mode: 'fade',
      auto: true
    };
    this.bindSlider = this.bindSlider.bind(this);
    this.getBanners = this.getBanners.bind(this);
  }

  componentWillMount() {
    //this.getBanners();
  }

  componentDidMount() {
    this.getBanners();
    window.addEventListener('load', () => {

      // setTimeout(() => {
      //   this.bindSlider();
      // }, 100)
      
     
    });

    if (document.readyState === "complete" || document.readyState === "interactive") {
      this.bindSlider();
    }
  }

  componentDidUpdate() {
    //window.addEventListener('load', this.bindSlider);

    if (document.readyState === "complete" || document.readyState === "interactive") {
      //this.bindSlider();
    }
  }

  getBanners() {
    let data = [
      {
        "id": 2,
        "caption_heading": "Looking for a Romantic Place?",
        "caption_description": "Browse Couples Packages",
        "caption_link": "https://www.islandhop./travel-packages/honeymoon?categories%5B%5D=honeymoon",
        "package_type": "International",
        "package_overview": "Over 20+ Holiday Packages",
        "banner_image": {
          "id": 1450,
          "name": "1711203138-1707547999-Swiss(1).jpg",
          "path": "https://api.islandhop.in/upload/attachments/1711203138-1707547999-Swiss(1).jpg",
          "type": "things_to_do_landscape_image",
          "mime_type": "image/jpeg"
        },
        "caption_image": {
          "id": 1451,
          "name": "1711203148-1707547999-Swiss(1).jpg",
          "path": "https://api.islandhop.in/upload/attachments/1711203148-1707547999-Swiss(1).jpg",
          "type": "things_to_do_landscape_image",
          "mime_type": "image/jpeg"
        }
      }
    ]
    this.setState({ banners: data});
    // return axios({
    //   method: 'GET',
    //   url: `${API_ROOT}/banner/listing`,
    // }).then(resp => {
    //   if (resp.data.result === "success") {
    //     this.setState({ banners: resp.data.payload });
    //     console.log(resp.data.payload);
    //     setTimeout(() => {
    //       this.bindSlider();
    //     }, 100)
        
    //   }
    // })
  }

  bindSlider() {
    const $ = window.$$;
    const windowNavHgt = $(window).height();
    $.fn.bannerHeight = function () {
      $('.cs-home-slider').css('height', windowNavHgt + 'px');
    }
    $.fn.bannerHeight();
    $(window).on('resize', function () {
      $.fn.bannerHeight();
    });

    if (this.slider !== null) {
      this.slider.reloadSlider(this.config);
    } else {
      this.slider = $(this.refs.bxsliderSlide).bxSlider(this.config);
    }
  }

  render() {
    const { banners } = this.state;
    return (
      <section className="cs-home-slider">
        <div className="bxslider-outr">
          <ul className="bxslider" ref="bxsliderSlide">
            {
              banners && banners.length > 0 &&
              banners.map(data => {
                return (
                  <li key={data.id} aria-hidden={false}>
                    <img src={data.banner_image.path} alt="" />
                    <div className="carousel-caption">
                      <div className="banner-bottom-content">
                        <div className="left-content">
                          <Link to={`/travel-packages?destination_type[]=${data.package_type.toLowerCase()}`}>
                            <h5>{data.package_type} Packages</h5>
                            <p>{data.package_overview}</p>
                          </Link>
                        </div>
                        <div className="right-content">
                          <a href={data.caption_link}>
                            <figure><img src={data.caption_image.path} alt="" /></figure>
                            <figcaption>
                              <h5>{data.caption_heading}</h5>
                              <p>{data.caption_description}</p>
                            </figcaption>
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                )
              })
            }
            {/* <li>
              <img src="images/banner1.jpg" alt="" />
              <div className="carousel-caption">
                <div className="banner-bottom-content">
                  <div className="left-content">
                    <a href="javascript:;">
                      <h5>International Packages</h5>
                      <p>Over 56 Holiday Packages in Switzerland</p>
                    </a>
                  </div>
                  <div className="right-content">
                    <Link to="/travel-packages?categories[]=honeymoon">
                      <figure><img src="/images/heart-balloon.png" alt="" /></figure>
                      <figcaption>
                        <h5>Looking for a Romantic Place?</h5>
                        <p>Browse Couples Packages</p>
                      </figcaption>
                    </Link>
                  </div>
                </div>
              </div>
            </li> */}
            {/* <li><img src="/images/banner2.png" alt="" />
              <div className="carousel-caption">
                <div className="banner-bottom-content">
                  <div className="left-content">
                    <Link to="/travel-packages?destination_type[]=international">
                      <h5>International Packages</h5>
                      <p>Over 23 Holiday Packages in Indonesia</p>
                    </Link>
                  </div>
                  <div className="right-content">
                    <Link to="/travel-packages?categories[]=family">
                      <figure><img src="/images/ic_happy.png" alt="" /></figure>
                      <figcaption>
                        <h5>Wanna plan a Family Trip?</h5>
                        <p>Budget Family Packages</p>
                      </figcaption>
                    </Link>
                  </div>
                </div>
              </div>
            </li> */}
            {/* <li><img src="images/banner3.png" alt="" />
              <div className="carousel-caption">
                <div className="banner-bottom-content">
                  <div className="left-content">
                    <Link to="/travel-packages?destination_type[]=domestic">
                      <h5>Domestic Packages</h5>
                      <p>Over 83 Holiday Packages in Himachal Pradesh</p>
                    </Link>
                  </div>
                  <div className="right-content">
                    <Link to="/travel-packages?categories[]=nature">
                      <figure><img src="/images/nature.png" alt="" /></figure>
                      <figcaption>
                        <h5>Looking for Nature Trip?</h5>
                        <p>Attractive Himalayas Packages</p>
                      </figcaption>
                    </Link>
                  </div>
                </div>
              </div>
            </li> */}
            {/* <li><img src="/images/banner4.png" alt="" />
              <div className="carousel-caption">
                <div className="banner-bottom-content">
                  <div className="left-content">
                    <Link to="/travel-packages?destination_type[]=domestic">
                      <h5>Domestic Packages</h5>
                      <p>Over 83 Holiday Packages in Himachal Pradesh</p>
                    </Link>
                  </div>
                  <div className="right-content">
                    <Link to="/travel-packages?categories[]=adventure">
                      <figure><img src="/images/fire.png" alt="" /></figure>
                      <figcaption>
                        <h5>Looking for Adventure Trip?</h5>
                        <p>Attractive Adventure Packages</p>
                      </figcaption>
                    </Link>
                  </div>
                </div>
              </div>
            </li> */}
          </ul>
          {/* start search */}
          <HomeSearch />
          {/* end  search */}
        </div>
      </section>
    );
  }
}